<template>
    <div class="classroom-forms-wrapper">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="94px" class="common-form" label-position="right">
            <el-form-item label="教室类型" prop="roomType">
                <el-radio-group class="classroom_type" v-model="ruleForm.roomType">
                    <el-radio
                        v-for="(item) of classroomType"
                        :key="item.id"
                        :label="item.id"
                    >{{ item.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="教室名称" prop="roomName" placeholder="请输入教室名称">
                <el-input v-model="ruleForm.roomName" maxlength="15" show-word-limit class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="教室描述" prop="roomDes" placeholder="请输入教室描述">
                <el-input v-model="ruleForm.roomDes" type="textarea" maxlength="200" show-word-limit class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="所在楼栋" prop="buildingId">
                <el-select
                    v-model="ruleForm.buildingId"
                    placeholder="请选择所在楼栋"
                    @change="selectBuilding"
                    class="input-width-medium"
                    >
                        <el-option
                            v-for="(item, index) in buildingList"
                            :key="index"
                            :value="item.value"
                            :label="item.label" />
                </el-select>
            </el-form-item>
            <el-form-item label="所在楼层" prop="floorId">
                <el-select v-model="ruleForm.floorId" placeholder="请选择所在楼层" class="input-width-medium">
                    <el-option
                        v-for="(item, index) in floorList"
                        :key="index"
                        :value="item.value"
                        :label="item.label" />
                </el-select>
            </el-form-item>
            <el-form-item label="门牌号" prop="roomNum" placeholder="请输入门牌号">
                <el-input v-model="ruleForm.roomNum" maxlength="15" show-word-limit class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="排序值" prop="sort" placeholder="请输入排序值">
                <el-input-number
                    class="input-width-medium"
                    v-model="ruleForm.sort"
                    :min="1"
                    :max="100000"
                    @change="handlerSortChange"
                ></el-input-number>
            </el-form-item>
            <div class="btn-wrapper">
                <el-button @click="resetForm('ruleForm')">重置</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
export default {
    name: "ClassroomForms",
    props: {
        ruleForm: {
            type: Object
        },
        rules: {
            type: Object
        },
        classroomType: {
            type: Array
        },
        buildingList: {
            type: Array
        },
        floorList: {
            type: Array
        }
    },
    methods: {
        selectBuilding () {
            this.$emit('selectBuilding')
        },
        submitForm () {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.$emit('submitForm')
                } else {
                    return false;
                }
            });
        },
        resetForm () {
            this.$refs['ruleForm'].resetFields();
        },
        handlerSortChange () {
            this.$emit('handlerSortChange')
        }
    }
}
</script>
<style lang="scss" >
	.classroom-forms-wrapper {
		.btn-wrapper {
		    width: 546px;
            text-align: right;
		}

		// 弹窗单选
		.el-radio {
			width: 72px;
			padding-top: 12px;
		}
	}
    .classroom_type{
        display: flex;
        // justify-content: space-between;
        width: 460px;
        flex-wrap: wrap;
    }
</style>
